@tailwind base;
@tailwind components;
@tailwind utilities;
@layer base {
    :root {
        --color-text--discreet: #6e6e6e;
        --primary: #068781;
        --primary-darker: #044e4a;
        --primary-white: #fff;
        --primary-gray: #666;
        --primary-gray--lighter: #ddd;
        --input-field-background: #ffffff;
        --color-input-background--disabled: #e7e7e7;
        --color-border--disabled: var(--color-input-background--disabled);
        --color-input-text--disabled: #6e6e6e;

        --surface: white;
    }
}
