.avatar {
    display: inline-block;
    vertical-align: middle;
    border-radius: 50%;
    background-color: #e0e0e0;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    line-height: 40px;
    text-align: center;
}

.avatar--small {
    width: 30px;
    height: 30px;
    line-height: 30px;
    font-size: 12px;
}

.avatar--medium {
    width: 40px;
    height: 40px;
    line-height: 40px;
    font-size: 20px;
}

.avatar--large {
    width: 80px;
    height: 80px;
    line-height: 80px;
    font-size: 40px;
}

.avatar__image {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
}
