@import '../../style/colors.css';
@import '../../style/utils.scss';

:root {
    --disabled: #acacac;
}
.button {
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    flex-shrink: 0;
    border: 0;
    padding: 0 32px;
    cursor: pointer;
    overflow: hidden;
    white-space: nowrap;
    font-size: 0.9em;
    line-height: 32px;
    height: 32px;
    border-radius: 8px;
    padding-bottom: 2px;
    user-select: none;
    text-decoration: none;
    text-align: center;
}
.button--primary {
    color: #fff;
    background-color: var(--primary) !important;
    @extend .ripple;
}
.button--warning {
    color: #fff;
    background-color: rgb(178, 28, 28) !important;
    @extend .ripple;
}

.button--primary:hover {
    background-color: var(--primary-darker) !important;
}
.button--primary:disabled,
.button--warning:disabled {
    background-color: var(--disabled) !important;
}
.button--outlined {
    border: 1px solid var(--primary);
    color: var(--primary);
    background-color: transparent;
}
.button--outlined:disabled {
    border: 1px solid var(--disabled);
    color: var(--disabled);
}

.button--text {
    border: none;
    padding: 0;
    color: var(--primary);
    background-color: transparent;
}

.button--large {
    line-height: 40px;
    height: 40px;
    font-size: 1em;
    border-radius: 25px;
    padding-bottom: 2px;
}
