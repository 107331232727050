@import '../../style/colors.css';

.input {
}
.input__control {
    box-sizing: border-box;
    width: 100%;
    color: var(--color-text--discreet);
    margin-top: 0.2em;
    border-radius: 8px;
    border: 1px solid rgb(216, 221, 230);
    line-height: 38px;
    padding: 0 14px;
}

.input__label {
    font-size: 0.8em;
    padding-left: 6px;
    color: var(--color-text--discreet);
}
.input__control--disabled {
    background-color: var(--color-input-background--disabled);
    border-color: var(--color-border--disabled);
    color: var(--color-input-text--disabled);
}

.datetime-picker {
    display: flex;

    gap: 4px;
    flex-direction: row;
    align-items: flex-end;
}

.datetime-picker__date-wrapper {
    flex: 1;
}

.datetime-picker__time-wrapper {
    max-width: 90px;
}
