@import '../../style/colors.css';

.tabs {
    display: flex;
    flex-direction: row;
    width: 100%;
    color: var(--color-text--discreet);
    border-bottom: 1px solid var(--primary-gray--lighter);
    composes: noselect from '../../style/utils.css';
}

.tabs__tab {
    margin: -2px 0;
    padding: 0.5em 3em;
    margin-bottom: -2px;
    cursor: pointer;
}

.tabs__tab--selected {
    border-bottom: 2px solid var(--primary);
    color: var(--primary);
}

.tabs__views {
    padding-top: 16px;
}
