.ListItem {
    padding: 1em;
    background-color: rgba(0, 0, 0, 0.1);
    text-decoration: none;
    color: black;
    font-weight: bold;
}
.ListItem:hover {
    padding: 1em;
    background-color: rgba(0, 0, 0, 0.15);
    text-decoration: none;
}

.nav-link.active > .ListItem {
    background-color: rgb(3, 57, 54);
}

.nav-link.active > .ListItem:hover {
    background-color: rgb(3, 67, 64);
}
