@tailwind base;
@tailwind components;
@tailwind utilities;
@import './style/colors.css';

html,
body {
    background-color: rgb(248, 248, 248);
    box-sizing: border-box;
}

.flex {
    display: flex;
}
.flex-col {
    flex-direction: column;
}

.flex-row {
    flex-direction: row;
}
.flex-gap {
    gap: 20px;
}

.flex-50 {
    flex: 0 0 50%;
}

@media screen and (max-width: 768px) {
    .mb-flex-100 {
        flex: 0 0 100%;
    }
}

@media screen and (max-width: 960px) {
    .pad-flex-100 {
        flex: 0 0 100%;
    }
}
