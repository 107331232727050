.user-panel {
    background-color: white;
    box-sizing: border-box;
    border-radius: 16px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    border-color: rgba(0, 0, 0, 0.1);
    padding: 2em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 58px;
    text-align: center;
    right: 20px;
}

.user-panel__avatar {
    margin-bottom: 1em;
}
