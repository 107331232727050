@import '../../style/colors.css';

.textarea {
    width: 100%;
    max-width: 100%;
    padding: 18px;
    margin-top: 4px;
    display: inline-block;
    background-color: var(--input-field-background);
    height: 32px;
    min-height: 200px;
    font-size: 1.2em;
    border: 1px solid rgb(216, 221, 230);

    border-radius: 8px;
    box-sizing: border-box;
}
.textarea::placeholder {
    font-size: 0.9em;
    color: var(--color-text--discreet);
}

.textarea__label {
    padding-left: 6px;
    box-sizing: border-box;
    font-size: 0.8em;
    color: var(--color-text--discreet);
}
