@media screen and (max-width: 768px) {
    .dropzone-form {
        height: 12rem;
    }
    .dropzone-form__input-label {
        border: none;
        height: auto;
        padding: 0;
        background-color: transparent;
    }

    .dropzone-form__input-label-text {
        display: none;
    }
}

@media screen and (min-width: 768px) {
    .dropzone-form {
        height: 16rem;
        max-width: 100%;
        width: 100%;
        text-align: center;
        position: relative;
    }

    .dropzone-form__input-label {
        height: 100%;
        display: flex;
        align-items: center;
        padding: 0 50px;
        justify-content: center;
        border-width: 2px;
        border-radius: 1rem;
        border-style: dashed;
        border-color: #cbd5e1;
        background-color: #f8fafc;
    }

    .dropzone-form__input-label .drag-active {
        background-color: #ffffff;
    }

    .dropzone-form__drag-file-surface {
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 1rem;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
    }
}

.dropzone-form__input {
    display: none;
}
