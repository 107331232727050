.notification {
    background-color: white;
    border-radius: 12px;
    align-self: flex-start;
    padding: 14px;
    width: 325px;
    flex-shrink: 0;
    box-shadow: 1px 3px 8px -5px rgb(0 0 0 / 75%);
    border: 1px solid #f5f5f5;
}

.notification__header {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
}

.notification__header__icon {
    border-radius: 4px;
    flex-shrink: 0;
    align-self: center;
    overflow: hidden;
}

.notification__header__title {
    margin: 0 0 0 8px;
    color: #bbb;
    font-size: 10px;
    font-weight: 500;
    text-transform: uppercase;
}

.notification__title {
    margin: 0;
    color: #333;
    font-size: 14px;
    font-weight: 500;
}

.notification__message {
    margin: 0;
    color: #333;
    font-size: 14px;
    font-weight: 400;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre-wrap;
}
