:root {
    --primary: #068781;
    --primary-darker-20: #044e4a;
    --primary-darker-50: rgb(3, 67, 4);
}

.container2 {
    display: flex;
    flex-direction: row;
    min-height: 100vh;
    align-items: center;
}

.section {
    margin-bottom: 20px;
}

.menu {
    background-color: var(--primary);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-self: flex-start;
    min-height: 100vh;
    position: sticky;
    height: 100%;
    width: 250px;
    flex-shrink: 0;
    border-right: 1px solid #eee;
    top: 0px;

    padding: 0;
    margin: 0;
    list-style: none;
}

.menu__logo-container {
    background-color: var(--primary-darker-20);
    height: 60px;
    display: flex;
    align-items: center;
}

.menu__settings-container {
    background-color: var(--primary-darker-50);
    height: 60px;
    display: flex;
    align-items: center;
}

.menu__logo-container__img {
    width: 150px;
    padding-left: 1em;
}

.menu--mobile {
    position: fixed;
    transform: translateX(-100%);
    transition: transform 0.3s ease-in-out;
    z-index: 9999;
}
.menu--mobile--open {
    transform: translateX(0);
}

.mobile-menu-toggle-button {
    position: absolute;
    z-index: 10000;
    right: -52px;
    top: 8px;
}

.content {
    display: flex;
    flex-direction: column;
    flex: 1;
    flex-basis: 100%;
    padding: 1em;
    padding-top: 49px;
    min-height: 100vh;
}

.nav-link {
    color: white;
    text-decoration: inherit;
}
.nav-link .ListItem {
    color: white;
    font-size: 14px;
    font-weight: normal;
}

.main {
    position: relative;
    flex: 1;
    min-height: 100vh;
}

.topbar {
    position: fixed;
    z-index: 9998;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    top: 0;
    left: 0;
    width: calc(100% - 251px);
    height: 49px;
    margin-left: 251px;
    padding: 0 20px;
    box-sizing: border-box;
    background-color: rgb(248, 248, 248);

    border-bottom: 1px solid #eee;
}

.topbar--mobile {
    margin-left: 0;
    width: 100%;
}
